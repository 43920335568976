import React, { FC, useCallback, useMemo } from 'react'
import { LinkProps, UrlType } from '@sport1/types/web'
import BigButton from '@sport1/news-styleguide/BigButton'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import { openPrivacyManager } from '@/components/ThirdParty/SourcePoint'
import { GLOBAL_LAYOUT_COMPONENT_SPACE } from '@/utils/content/constants'
import nofollowExternal from '@/utils/url/nofollowExternal'
import { sport1RegularItalic } from '@/helpers/fonts'

const Link: FC<LinkProps> = ({ title, url, urlType }) => {
    const onPress = useCallback(() => {
        // check url privacy marker fuzzy
        if (url?.includes('sport1://privacy')) {
            openPrivacyManager()
        }
        if (title === 'AT-Internet Opt-Out' && typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({ event: 'xiti_optout' })
        }
    }, [url, title])

    const renderLink = useMemo(() => {
        if (urlType === UrlType.EMAIL) {
            return (
                <Font
                    lineHeight={['lineHeight-26', 'lineHeight-26', 'lineHeight-26']}
                    fontSize={['font-size-6', 'font-size-6', 'font-size-6']}
                    testID="article-mailto-link"
                    nextFontFamilyVariant={sport1RegularItalic.style.fontFamily}
                    fontFamilyVariant="Sport1-RegularItalic"
                    paddingBottom="spacing-4"
                    underline
                    href={url}
                >
                    {title}
                </Font>
            )
        }
        return (
            <BigButton
                backgroundColor="moon-2"
                onPress={onPress}
                webRel={nofollowExternal}
                testID="article-link-button"
            >
                {title}
            </BigButton>
        )
    }, [url, title, urlType, onPress])

    return (
        <NonFlexingContainer
            testID="article-link"
            marginX={GLOBAL_LAYOUT_COMPONENT_SPACE}
            marginBottom="spacing-7"
        >
            {renderLink}
        </NonFlexingContainer>
    )
}

export default Link
